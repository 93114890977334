import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

/*  Кастом плагины   */
import './plugins/element-ui';
import './plugins/fa-icons';
import './plugins/vue-cookie';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
