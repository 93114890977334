import Vue from 'vue';
import {
  Button, Card, Input, Form, FormItem, Dialog, Slider,
  InputNumber,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.component('el-button', Button);
Vue.component('el-card', Card);
Vue.component('el-input', Input);
Vue.component('el-form', Form);
Vue.component('el-form-item', FormItem);
Vue.component('el-dialog', Dialog);
Vue.component('el-slider', Slider);
Vue.component('el-input-number', InputNumber);
// Vue.use(ElementUI);
