<template>
  <div class="header">
    <img src="@/assets/img/logo_ion.png"
         class="header__logo"
         width="100" height="90"
         style="margin-left: 20px"
    >
    <div class="mr-2 align-items-center" v-if="userInfo !== null">
      {{userInfo.login ? userInfo.login : 'login'}}
      <el-button size="mini" round type="danger" @click="logOut">выйти</el-button>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /**
   * Компонент шапка страницы
   */
  name: 'Header',
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  async created() {
    /**
     * При создании шапки проверяется наличие кук и вызов функций
     * получения данных если куки были найдены
     */
    if (this.$cookies.get('token')) {
      await this.$store.dispatch('getUserInfo');
      await this.$store.dispatch('getRooms');
    }
  },
  methods: {
    logOut() {
      /**
       * Функция для выхода из аккаунта
       */
      this.$store.commit('logOut');
      try {
        this.$router.push('/login');
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
  .header{
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-bottom: solid 1px rgba(0,0,0,0.1);
  }
  @media (max-width: 500px), (max-height: 500px) {
    .header__logo{
      width: 80px;
      height: 72px;
    }
  }
</style>
