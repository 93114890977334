import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret, faLightbulb, faSignOutAlt, faPlug, faWater,
  faThermometerHalf, faTint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faUserSecret, faLightbulb, faSignOutAlt, faPlug, faWater,
  faThermometerHalf, faTint,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
