import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { Message } from 'element-ui';

Vue.use(Vuex);
// eslint-disable-next-line no-underscore-dangle
axios.defaults.headers.common.Authorization = `Bearer ${Vue.$cookies.get('token')}`;

export default new Vuex.Store({
  state: {
    userInfo: null,
    rooms: [],
  },
  getters: {
    userInfo: (state) => state.userInfo,
    rooms: (state) => state.rooms,
  },
  mutations: {
    logOut(state) {
      state.userInfo = null;
      state.rooms = [];
      Vue.$cookies.remove('token');
      axios.defaults.headers.common.Authorization = '';
    },
    changePossibilityValue(state, data) {
      let testNum = null;
      if (data.deviceId && data.possibilityKey && data.newValue) {
        state.rooms.forEach((room) => {
          if (room.devices && Array.isArray(room.devices)) {
            room.devices.forEach((device) => {
              if (device.id && device.id === data.deviceId && device.possibilities) {
                testNum = parseFloat(data.newValue);
                if (Number.isNaN(testNum)) {
                  // eslint-disable-next-line no-param-reassign
                  device.possibilities[data.possibilityKey] = data.newValue;
                } else {
                  // eslint-disable-next-line no-param-reassign
                  device.possibilities[data.possibilityKey] = testNum;
                }
              }
            });
          }
        });
      }
    },
  },
  actions: {
    /**
     * Авторизация
     */
    async auth({ dispatch }, data) {
      let err = 'Ошибка';
      await axios
        .post('/api/external/authorization/login/', data)
        .then((res) => {
          if (res.status === 200 && res.data.message && res.data.message === 'success') {
            err = false;
            // eslint-disable-next-line no-underscore-dangle
            axios.defaults.headers.common.Authorization = `Bearer ${this._vm.$cookies.get('token')}`;
          }
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.detail) {
            err = error.response.data.detail;
          }
          console.log('error = ', error.response);
        });
      if (!err) {
        await dispatch('getUserInfo');
        await dispatch('getRooms');
      }
      return err;
    },
    async getUserInfo({ state, dispatch }) {
      await axios
        .get('/api/external/authorization/user_info/')
        .then((res) => {
          if (res.status === 200) {
            state.userInfo = res.data;
            dispatch('connectToSocket');
          }
        }).catch((error) => {
          Message.error('Ошибка получения информации о пользователе');
          console.log(error);
        });
    },
    async getRooms({ state }) {
      await axios
        .get('/api/external/room/')
        .then((res) => {
          if (res.status === 200) {
            state.rooms = res.data;
          }
        })
        .catch((error) => {
          Message.error('Ошибка получения спика комнат');
          console.log(error);
        });
    },

    async changePossibilityValue({ commit }, data) {
      if (data.deviceId && data.possibilityKey && data.newValue) {
        await axios
          .patch(`/api/external/device/${data.deviceId}/possibility/${data.possibilityKey}/`,
            {
              value: data.newValue,
            })
          .then((res) => {
            if (res.status === 200) {
              commit('changePossibilityValue', data);
            }
          })
          .catch((error) => {
            Message.error('Ошибка при обновлении состояния устройства');
            console.log(error);
          });
      }
    },

    async connectToSocket({ commit, dispatch }) {
      const wsPort = window.location.port ? `:${window.location.port}` : '';
      const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      let wsUrl = '';
      if (process.env.NODE_ENV === 'production') {
        wsUrl = `${wsProtocol}://${window.location.hostname}${wsPort}/ws/external/possibilities_update/`;
      } else {
        wsUrl = 'ws://localhost:8000/ws/external/possibilities_update/';
      }
      const connection = new WebSocket(wsUrl);
      connection.onmessage = function (event) {
        let data = null;
        if (event && event.data) {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            console.log(e);
          }
          if (data && 'key' in data && 'value' in data && 'device' in data) {
            commit('changePossibilityValue', {
              deviceId: data.device,
              possibilityKey: data.key,
              newValue: data.value,
            });
          }
        }
      };
      connection.onclose = () => {
        setTimeout(async () => {
          await dispatch('connectToSocket');
        }, 3000);
      };
    },

  },
  modules: {
  },
});
